import React, { useEffect } from "react";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronUpIcon } from "feather-icons/dist/icons/chevron-up.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

import SimpleFiveColumn from "./components/footers/SimpleFiveColumn";
import ThreeColSlider from "./components/cards/ThreeColSlider";
import TwoColumnWithImage from "./components/testimonials/TwoColumnWithImage";
import ThreeColWithSideImage from "./components/features/ThreeColWithSideImage";
import SimplePrimaryBackground from "./components/testimonials/SimplePrimaryBackground";
import {CookieConsent} from "react-cookie-consent";

/* Hero */
const Link = tw.a`border-b-2 border-transparent hocus:text-localadra-1 hocus:border-localadra-1 pb-1 transition duration-300`;




const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const UpButton = tw(ControlButton)`fixed right-[20px] bottom-[20px]`;


export default ({
  features = null,
  primaryButtonUrl = "#landingPageDemos",
  primaryButtonText = "Explore Demos",
  secondaryButtonUrl = "#componentDemos",
  secondaryButtonText = "View Components",
  buttonRoundedCss = "",
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
  description = "Easily customizable modern React UI Templates and Components built using TailwindCSS which are also lightweight and simple to setup. All components are modular and fully responsive for great mobile experience as well as big desktop screens.  Brand Colors are also fully customizable. Free for personal as well as commercial use."
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, [])






  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce((acc, block) => acc + Object.keys(block.elements).length, 0);

  features = features || [
    `${noOfLandingPages} Landing Page Demos`,
    `${noOfInnerPages} Inner Pages`,
    `${noOfComponentBlocks} Components`,
    "Uses TailwindCSS",
    "Fully Responsive",
    "Fully Customizable"
  ];

  const { t } = useTranslation();

  return (
    <AnimationRevealPage enabled>
      <Container tw="bg-localadra-light -mx-8 -mt-8 pt-8 px-8">
        <Hero/>
        <TwoColumnWithImage/>
        <ThreeColSlider/>
        <ThreeColWithSideImage/>
        <SimplePrimaryBackground/>
        <SimpleFiveColumn/>
        <UpButton onClick={()=>window.scrollTo({top: 0, behavior: 'smooth'})} ><ChevronUpIcon/></UpButton>
        <CookieConsent
            buttonStyle={{ backgroundColor: "#c56e53", color: "white" }}
            style={{ backgroundColor: "#c5af9d"}}
            location="bottom"
            buttonText="Ok"
            cookieName="coockieConsent"
            expires={150}
        >
          {t("cookie.popup")} <Link href="/privacy">cookie policy</Link>
        </CookieConsent>
      </Container>
    </AnimationRevealPage>
  );
};

