import React from "react";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import GoogleMapReact from "google-map-react";



export default () => {

  const defaultProps = {
    center: {
      lat: 44.3497072,
      lng: 11.3919495
    },
    zoom: 17
  };

  const createMapOptions = () => {
      return {
          styles: [{
              featureType: 'all',
              elementType: 'labels',
              stylers: [{
                  visibility: 'on'
              }]
          }]
      }
  }

  const ContentWithMapXl= tw.div`h-128 mx-[-2rem]`;

  return (


      <ContentWithMapXl id="footer">

        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyDjD__x4FJcj_RAaYo2THAVYTdn0br19iE",
                libraries:['places', 'geometry', 'drawing', 'visualization'],}}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={createMapOptions}
        >
        </GoogleMapReact>

      </ContentWithMapXl>

  );
};
