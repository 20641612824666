import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram-logo.svg";
import {useTranslation} from "react-i18next";

const Container = tw.div`relative bg-localadra-dark -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-localadra-1 hocus:border-localadra-1 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-8 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block mr-8`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default () => {

  const { t } = useTranslation();

  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <CompanyDescription>
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/Localadra" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/localadra/" target="_blank">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">{t("menu.home")}</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">{t("menu.who")}</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">{t("menu.hospitality")}</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">{t("menu.contacts")}</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Contacts</ColumnHeading>
          <LinkList>
            Localadra B&B <br/>
            Via Ca di Gennaro 3 <br/>
            40065 Pianoro (Bo) Italy
            <LinkListItem>
              <Link href="mailto:info@localadra.it" target="_blank">E:info@localadra.it</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="tel:+393394748189">T:+39 3394748189</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Legal</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/privacy">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/cookie-policy">Cookie Policy</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
