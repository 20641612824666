import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import {useTranslation} from "react-i18next";

const HeadingRow = tw.div`flex`;
export const NavLink = tw.a`
  text-lg lg:text-sm lg:my-0 text-primary-500
  font-semibold tracking-wide transition duration-300
  pb-1 border-b border-transparent hover:border-primary-500 hocus:text-primary-500
`;
const Heading = tw(SectionHeading)`mb-10`;
const links = [{title: "Apple Safari", url: "https://support.apple.com/guide/safari/sfri11471/mac"},
  {title: "Google Chrome", url: "https://support.google.com/chrome/answer/95647"},
  {title: " Microsoft Internet Explorer", url: "https://support.microsoft.com/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"},
  {title: "Mozilla Firefox", url: "https://support.mozilla.org/kb/enhanced-tracking-protection-firefox-desktop"},
  {title: "Opera", url: "https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"},
  {title: "Your Online Choices.", url: "https://www.youronlinechoices.com/"}]
const Text = styled.div`
  ${tw`text-base  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-lg font-bold mt-8`}
  }
  h3 {
    ${tw`text-base font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {

  const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t("cookie.title")}</Heading>
          </HeadingRow>
          <Text>
            <h1>{t("cookie.h1.1")}</h1>
            <p>{t("cookie.text.1")}</p>

            <h2>{t("cookie.h2.1")}</h2>
            <p>{t("cookie.text.1.1")}</p>
            <h2>{t("cookie.h2.2")}</h2>
            <p>{t("cookie.text.1.2")}</p>
            <h2>{t("cookie.h2.3")}</h2>
            <p>{t("cookie.text.1.3")}</p>
            <h2>{t("cookie.h2.4")}</h2>
            <p>{t("cookie.text.1.4")}</p>
            <h2>{t("cookie.h2.5")}</h2>
            <p>{t("cookie.text.1.5")}</p>
            <h2>{t("cookie.h2.6")}</h2>
            <p>{t("cookie.text.1.6")}</p>
            <ul>
              <li>
                <NavLink href={links[0].url}>{links[0].title}</NavLink>
              </li>
              <li>
                <NavLink href={links[1].url}>{links[1].title}</NavLink>
              </li>
              <li>
                <NavLink href={links[2].url}>{links[2].title}</NavLink>
              </li>
              <li>
                <NavLink href={links[3].url}>{links[3].title}</NavLink>
              </li>
              <li>
                <NavLink href={links[4].url}>{links[4].title}</NavLink>
              </li>
            </ul>
            <p>{t("cookie.text.1.7")}</p>
            <NavLink href={links[5].url}>{links[5].title}</NavLink>

          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
