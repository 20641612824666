import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as TVIcon } from "feather-icons/dist/icons/tv.svg";
import { ReactComponent as WiFiIcon } from "feather-icons/dist/icons/wifi.svg";
import { ReactComponent as Coffee } from "feather-icons/dist/icons/coffee.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as DishWasherIcon } from "images/lavatrice.svg";
import { ReactComponent as Forno } from "images/forno.svg";
import { ReactComponent as Fan } from "images/fan.svg";

import {useTranslation} from "react-i18next";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`ml-[42%]`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const PrevButtonCard = tw(ControlButton)`relative top-[40%] left-[5px] opacity-[0.6]`;
const NextButtonCard = tw(ControlButton)`relative top-[40%] float-right mr-[5px] opacity-[0.6]`;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative 
focus:outline-none bg-white`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-xl`;

const Quote = tw.div`ml-2 italic text-sm`;
const QuoteAuthor = tw.div`ml-2 text-sm`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-row mt-2 sm:mt-4`;
const IconContainer = styled.div`
  ${tw`inline-block p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  const { t } = useTranslation();

  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  /* Change this according to your needs */
  const cards = [
    {
      title: t("hospitality.title1"),
      quote: t("hospitality.quote1"),
      quoteAuthor: t("hospitality.quote.author1"),
      description: t("hospitality.desc1"),
      locationText: "",
      pricingText: t("hospitality.price1"),
      rating: "",
      icons: [<TVIcon/>, <WiFiIcon/>, <Coffee/>, <Fan/>],
      book: t("hospitality.button1"),
      bookLink: t("hospitality.link1"),
      images: ["/localadra/est/1.jpg", "/localadra/est/3.jpg",
        "/localadra/est/4.jpg", "/localadra/est/2.jpg", "/localadra/est/5.jpg"],
      count: count1,
      setCount: setCount1
    },
    {
      title: t("hospitality.title2"),
      quote: t("hospitality.quote2"),
      quoteAuthor: t("hospitality.quote.author2"),
      description: t("hospitality.desc2"),
      locationText: "",
      pricingText: t("hospitality.price2"),
      bookLink: t("hospitality.link2"),
      rating: "",
      icons: [<TVIcon/>, <WiFiIcon/>, <Coffee/>, <Fan/>],
      book: t("hospitality.button2"),
      images: ["/localadra/ovest/1.jpg", "/localadra/ovest/2.jpg", "/localadra/ovest/3.jpg",
        "/localadra/ovest/4.jpg", "/localadra/ovest/5.jpg"],
      count: count2,
      setCount: setCount2
    },
    {
      title: t("hospitality.title3"),
      quote: t("hospitality.quote3"),
      quoteAuthor: t("hospitality.quote.author3"),
      description: t("hospitality.desc3"),
      locationText: "",
      pricingText: t("hospitality.price3"),
      bookLink: t("hospitality.link3"),
      rating: "",
      icons: [<TVIcon/>, <WiFiIcon/>, <Forno/>, <DishWasherIcon/>],
      book: t("hospitality.button3"),
      images: ["/localadra/stalla/1.jpg", "/localadra/stalla/2.jpg", "/localadra/stalla/3.jpg",
        "/localadra/stalla/4.jpg", "/localadra/stalla/5.jpg"],
      count: count3,
      setCount: setCount3
    }
  ]



  return (
    <Container id="ospitalita">
      <Content>
        <HeadingWithControl>
          <Heading>{t('menu.hospitality')}</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.images[card.count]}>
                <PrevButtonCard
                    onClick={() => card.setCount( card.count === 0 ? (card.images.length - 1) : card.count -1)}>
                  <ChevronLeftIcon/>
                </PrevButtonCard>
                <NextButtonCard
                    onClick={() => card.setCount( card.count === (card.images.length - 1) ? 0 : card.count +1)}>
                  <ChevronRightIcon/>
                </NextButtonCard>
              </CardImage>
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                </TitleReviewContainer>
                <Quote>{card.quote}</Quote>
                <QuoteAuthor>{card.quoteAuthor}</QuoteAuthor>
                <SecondaryInfoContainer>
                  {
                    card.icons.map((el) => (
                      <IconContainer>{el}</IconContainer>
                    ))
                  }
                </SecondaryInfoContainer>
                <Text>{card.pricingText}</Text>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton onClick={()=>{window.open(card.bookLink, "_blank")}}>{card.book}</PrimaryButton>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
