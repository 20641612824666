import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import {useTranslation} from "react-i18next";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`mb-10`;
const Text = styled.div`
  ${tw`text-base  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-lg font-bold mt-8`}
  }
  h3 {
    ${tw`text-base font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {

  const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t("privacy.title")}</Heading>
          </HeadingRow>
          <Text>
            <p>{t("privacy.text1")}</p>
            <h1>{t("privacy.title.h11")}</h1>
            <h2>{t("privacy.title.h21")}</h2>
            <p>{t("privacy.text2")}</p>
            <h2>{t("privacy.title.h22")}</h2>
            <p>{t("privacy.text3")}</p>
            <h2>{t("privacy.title.h23")}</h2>
            <p>{t("privacy.text4")}</p>
            <h2>{t("privacy.title.h24")}</h2>
            <p>{t("privacy.text5")}</p>
            <h2>{t("privacy.title.h25")}</h2>
            <p>{t("privacy.text6")}</p>
            <h2>{t("privacy.title.h26")}</h2>
            <p>{t("privacy.text7")}</p>
            <h2>{t("privacy.title.h27")}</h2>
            <p>{t("privacy.text8")}</p>
            <h2>{t("privacy.title.h28")}</h2>
            <p>{t("privacy.text9")}</p>
            <h2>{t("privacy.title.h29")}</h2>
            <p>{t("privacy.text10")}</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
