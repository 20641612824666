import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "title": "Home - Welcome to Localadra",
            "menu.home": "Home",
            "menu.dove": "Contacts",
            "menu.who": "Ronco Ceresa",
            "menu.hospitality": "Hospitality",
            "menu.contacts": "Services",
            "who.names": "Localadra Bed and Breakfast",
            "who.role": "at Ronco Ceresa",
            "hospitality.title1": "East room",
            "hospitality.title2": "West room",
            "hospitality.title3": "Ex-stable apartment",
            "hospitality.price1": "Starting from 80 €/Day",
            "hospitality.price2": "Starting from 80 €/Day",
            "hospitality.price3": "N.A.",
            "hospitality.desc1": "Room with 9 square meters of private bathroom, spacious shower and concrete sink." +
                " There is free Wi-Fi and Smart TV. The room, with a king size bed, has a small desk, linen sheets " +
                "and double pillows, mosquito nets and internal shutters. From the room you can admire the sunrise",
            "hospitality.desc2": "Room with private bathroom, spacious shower and cement floor. There is free " +
                "Wi-Fi and TV. The room has linen sheets and double pillows, mosquito nets, internal and external" +
                " shutters. From the room you can admire the sunset",
            "hospitality.desc3": "The apartment, with private bathroom, is housed in an old restored stable. " +
            "The bedroom has a double bed and is equipped with a cot for children up to 4 years. Kitchen and living " +
            "room are in a single space with two single sofa bed. There is a TV, free Wi-Fi and a wood stove.",
            "hospitality.quote1": '" Morning is due to all. To some the Night. To an imperial few The Auroral Light "',
            "hospitality.quote2": "\" Everything is imperfect, there is no sunset so beautiful that it could not be more \"",
            "hospitality.quote3": "\" There is no certainty that God has entrusted man with the dominion " +
                "over other creatures. It is more likely that man invented God to sanctify the dominion that he had " +
                "usurped over the cow and the horse. \"",
            "hospitality.quote.author1": "Emily Dickinson",
            "hospitality.quote.author2": "Fernando Pessoa",
            "hospitality.quote.author3": "Milan Kundera",
            "hospitality.button1": "More Info",
            "hospitality.button2": "More Info",
            "hospitality.button3": "More Info",
            "hospitality.link1": "mailto:info@localadra.it?subject=East room info request",
            "hospitality.link2": "mailto:info@localadra.it?subject=West room info request",
            "hospitality.link3": "mailto:info@localadra.it?subject=Ex Stable room info request",
            "about.us": "Localadra bed and breakfast is located in an old stone farmhouse called \"Ronco Ceresa\", " +
                "with a rough and tough soul typical of Apennine rural houses. The existence of the cottage is " +
                "already evidenced about 300 years ago, but over the years it has certainly been modified, worked " +
                "on, destroyed and rebuilt. Because of its strategic position, it was the base of the allies army " +
                "during the Second World War. Set on a small hill, from here it ranges 360°: Monte delle Formiche " +
                "Sanctuary looks at it from the south side while to the west the gaze reaches Monte Cimone. Here " +
                "we first built our house and then, little by little, we decided to open it to others. Our job was" +
                " to recover the atmosphere of the country house as much as possible, while adding all the possible" +
                " comforts without distorting its idea. Bologna is the closest city and is only 30 kilometers away" +
                " but in about one hour by car you can get to Florence. The surroundings, on the other hand, are " +
                "all to be discovered: rich in traditions and places to admire, there is no shortage of history" +
                " and of paths to be covered on foot, by bicycle or on horseback.",
            "services.1": "breakfast (rooms only)",
            "services.2": "wi-fi",
            "services.3": "tv",
            "services.4": "portable fan (rooms only)",
            "services.5": "shared refrigerator (rooms only)",
            "services.6": "towels",
            "services.7": "linen sheets",
            "services.8": "parking area",
            "privacy.title": "INFORMATION ON THE PROCESSING OF PERSONAL DATA",
            "privacy.text1": "This Notice is made to inform you that the data provided to our Company will be " +
                "processed pursuant to EU Regulation no. 2016/679 (hereinafter also “GDPR”) and Legislative Decree" +
                " 101/2018.",
            "privacy.title.h11": "We therefore invite you to carefully read the following information.",
            "privacy.title.h21": "Data Controller and Data Protection.",
            "privacy.text2": "The Data Controller is B&B Localadra, based in Via Ca di Gennaro, 3 40065 Pianoro (BO)" +
                " Italy. The data controller may be contacted by sending a registered letter with return receipt to" +
                " the above address or by e-mail info@localadra.it",
            "privacy.title.h22": "Purpose of the processing.",
            "privacy.text3": "The personal data provided will be processed in relation to the following purposes" +
                " i.) Fulfillment of contractual obligations; ii.) fulfillment of accounting and tax obligations;" +
                " iii.) suppliers  management (suppliers administration, contract administration, orders, deliveries" +
                " and invoices); iv.) customer management (customer administration, contract administration, orders," +
                " shipments and invoices, reliability and solvency control); v.) legal and economic treatment of " +
                "personnel; vi.) litigation management (contractual defaults, warnings, transactions, debt collection," +
                " arbitration, legal disputes); vii.) fulfillment of legal obligations; viii.) legitimate interest of" +
                " the Data Controller.",
            "privacy.title.h23": "Field of communication and diffusion.",
            "privacy.text4": "We inform you that, in order to achieve the aforementioned purposes, the data collected" +
                " may be communicated to third parties – appropriately designated as Data Controlers and whose " +
                "references will be communicated to you upon request, such as – financial, social security and welfare" +
                " institutions insurance companies, IT and telematics service companies, archiving and storage" +
                " companies, printing and shipping companies, e-mail management companies, judicial or administrative" +
                " authorities, accountants, lawyers, consultants, medical doctors, subsidiary or associated " +
                "companies, and the central for risks , auditing companies, foundations and university institutes " +
                "and others, trade associations. Outside of the aforementioned hypotheses, your personal data will" +
                " not be communicated or disclosed without your explicit consent.",
            "privacy.title.h24": "Processing methods.",
            "privacy.text5": "The processing will be carried out both in paper and automated form, in compliance " +
                "with the principles of lawfulness, correctness, non-exceeding and relevance provided for by current" +
                " legislation. The Data Controller adopts appropriate security measures, aimed at preventing " +
                "unauthorized access, disclosure, modification or destruction of the data processed",
            "privacy.title.h25": "Data retention.",
            "privacy.text6": "The Data Controller will process the personal data for the time strictly necessary to" +
                " fulfill the aforementioned purposes or, in any case, for the period provided for the fulfillment" +
                " of legal obligations, provided that it is not necessary to keep them further to fulfill orders" +
                " from the Authorities.",
            "privacy.title.h26": "Place of processing and scope of data circulation.",
            "privacy.text7": "The management and storage of personal data will take place on the Data Controller" +
                " server and / or third party companies duly appointed as Data Processors, located both within the" +
                " European Union and in non-EU countries. The data, therefore, can be transferred both inside and" +
                " outside the European Union. In relation to the possible transfer of data outside the EU the Data" +
                " Controller ensures from now on that the same occurs in accordance with the provisions of the law" +
                " through the stipulation of agreements that guarantee an adequate level of protection and through" +
                " the adoption of the standard contractual clauses envisaged by the European Commission.",
            "privacy.title.h27": "Refusal to provide data.",
            "privacy.text8": "We also consider it a duty to inform you that any refusal to consent to the treatments" +
                " indicated could prevent the completion of the contractual relationship.",
            "privacy.title.h28": "Rights of the interested parties.",
            "privacy.text9": "In relation to the processing of any personal data provided by our Company, we remind" +
                " you that, as an interested party, you have the right to:– obtain confirmation of the existence or" +
                " not of personal data concerning you, even if not yet recorded, and their communication in" +
                " intelligible form; - obtain the indication: a) of the origin of the personal data; b) the purposes" +
                " and methods of processing; c) the logic applied in the case of processing carried out with the aid" +
                " of electronic instruments; d) of the identification data concerning the Data Controller, data" +
                " processors and the representative designated pursuant to art. 3, paragraph 1, GDPR and Legislative" +
                " Decree 101/2018; e) the subjects or categories of subjects to whom the personal data may be" +
                " communicated or who can learn about them as appointed representative in the State, managers or " +
                "appointees; – obtain: a) updating, rectification or, when interested, integration of data; b) the" +
                " deletion, transformation into anonymous form or blocking of data processed in violation of the" +
                " law, including data which does not need to be kept for the purposes for which the data was " +
                "collected or subsequently processed; c) the attestation that the operations referred to in letters" +
                " a) and b) have been brought to the attention, also with regard to their content, of those to whom" +
                " the data have been communicated or disseminated, except in the event that such fulfillment occurs" +
                " it proves impossible or involves a manifestly disproportionate use of resources with respect to" +
                " the protected right; – object, in whole or in part: a) for legitimate reasons to the processing of " +
                "your personal data, even if pertinent to the purpose of collection; b) to the processing of your " +
                "personal data for the purpose of sending advertising material or direct sales or for carrying out " +
                "market research or commercial communication, through the use of automated call systems without the " +
                "intervention of an operator by e-mail and / or through traditional marketing methods by phone and /" +
                " or mail. Please note that the interested party’s right of objection, set out in the previous point" +
                " b), for direct marketing purposes by automated means, extends to the traditional ones and that the" +
                " possibility for the interested party to exercise the right of opposition also remains valid only" +
                " partially. Therefore, the interested party may decide to receive only communications using " +
                "traditional methods or only automated communications or none of the two types of communication; – " +
                "where applicable, you also have the rights pursuant to articles 16-21 GDPR (Right of rectification," +
                " right to oblivion, right to limitation of treatment, right to portability of contractual and raw" +
                " navigation data, right to object), as well as the right to complain to the Guarantor for the" +
                " Protection of Personal Data.",
            "privacy.title.h29": "Changes to the Information Notice.",
            "privacy.text10": "The possible entry into force of new sector regulations, as well as the constant " +
                "examination and updating of the services reserved to you. may involve the need to change the " +
                "methods and terms described in this Statement. It is therefore possible that this document may " +
                "change over time. You are therefore advised to periodically consult the dedicated page on the " +
                "www.localadra.it/privacy company website as it will publish any changes to this Information Notice.",
            "cookie.title": "Cookie Policy",
            "cookie.h1.1": "TYPES OF DATA PROCESSED AND PURPOSE OF THE TREATMENT",
            "cookie.text.1": "During the navigation on this site some personal data of the users could be processed," +
                " which can be schematized as follows.",
            "cookie.h2.1": "a) Data provided voluntarily by the user",
            "cookie.text.1.1": "The data optionally and freely provided by the user by sending e-mail messages to " +
                "the e-mail addresses indicated on the site will be acquired and stored by the Data Controller, in " +
                "electronic form, for the purpose of managing correspondence, supplying the service requested by the" +
                " user and to verify the use of the site and the quality of the services offered. In particular, in" +
                " addition to the name and e-mail address of the user, which is necessary to respond to the related" +
                " requests, the other personal data that the electronic communication may refer to or that are " +
                "contained in it, may be acquired.",
            "cookie.h2.2": "b) Navigation data",
            "cookie.text.1.2": "Some data related to navigation on the site by the user could also be acquired." +
                " It is, by way of example, demographic data, geographical data and other data more specifically" +
                " related to the user’s behavior on the site. These data are collected exclusively for the purpose" +
                " of obtaining anonymous statistical information on the use of the site. Such information, however," +
                " if processed and associated with data held by third parties (and therefore not in the possession " +
                "of the Data Controller), could allow to trace the identity of the user. The navigation data, in any" +
                " case, are not used by the Data Controller to perform direct user profiling activities.",
            "cookie.h2.3": "c) Cookies",
            "cookie.text.1.3": "Cookies are small text files that the sites visited by the user send and record on " +
                "their computer or mobile device, to be re-transmitted to the same sites on the next visit. Thanks" +
                " to cookies, a site can remember the user’s actions and preferences, so that he does not have to " +
                "run them again every time he accesses the site or when you navigate from one page to another. " +
                "Cookies are usually used to perform automatic authentication, session monitoring and storage of " +
                "specific information regarding users accessing the server; they can also contain unique " +
                "identification codes that allow the site manager to keep track of the user’s navigation and to " +
                "recognize the device. The user can disable or accept cookies by properly configuring their browser." +
                " In some cases, however, the use of cookies is essential for the operation of the site and, " +
                "therefore, some operations may not be possible in the event of their deactivation.",
            "cookie.h2.4": "TYPES OF COOKIES",
            "cookie.text.1.4": "There are different types of cookies, which, according to their peculiarities and " +
                "functions, are classified into: – session cookies (which are temporarily stored on the user’s device," +
                " allowing websites to link the actions performed by the same during browsing, and which are " +
                "automatically deleted at the end of the browser session) or persistent cookies (which, instead," +
                " they remain active on the user’s device until their natural expiration or their manual deletion " +
                "and allow the user’s preferences to be stored permanently with respect to a specific website); – " +
                "technical cookies (which are used for the sole purpose of transmitting a communication over an " +
                "electronic communication network, or to the extent strictly necessary to provide a service " +
                "explicitly requested by the user) or profiling cookies (which are instead intended for create " +
                "user profiles, in order to send him advertising messages in line with the preferences shown by " +
                "the user himself  in the context of navigation); – first-party cookies (set and managed by the owner " +
                "of the site visited by the user) or third-party cookies (which are set and managed by a third party " +
                "with respect to the site the user is visiting).",
            "cookie.h2.5": "TYPES OF COOKIES USED BY THIS SITE",
            "cookie.text.1.5": "In full respect of the rights of the user and in order to allow a correct and aware" +
                " navigation, it is specified that this site uses the following types of cookies. a) First-party" +
                " cookies: The first-party cookies used on the site have an exclusively technical nature and" +
                " can be distinguished in navigation cookies, which allow to improve the user’s browsing experience," +
                " and functional cookies, necessary to provide the service or improve it. b) Third-party cookies: " +
                "This site uses so-called “analytical cookies”, created and made available by Google Analytics and" +
                " designed to allow mere statistical analysis of access to the site and to monitor its correct " +
                "functioning. For detailed information regarding this type of cookie, the user is invited to consult" +
                " Google’s policy, which can be reached at the link https://policies.google.com/privacy. In addition" +
                " to analytical cookies, this site also uses so-called “widgets”. The site uses, in particular," +
                " Google Maps widgets, which, if used by the user, will send one or more cookies on behalf of the" +
                " chosen platform. The operator of this site does not have nor can have any control over the content" +
                " and the method of data processing operated through these widgets and therefore the user is invited" +
                " to make a conscious use of it, after consulting Google policy (https://policies.google.com/privacy)." +
                " However, no profiling cookies are used on this site.",
            "cookie.h2.6": "MANAGEMENT OF COOKIES WITH BROWSER CONFIGURATION",
            "cookie.text.1.6": "The user will be able to manage the cookie preferences through the settings of his" +
                " browser, bearing in mind, however, that changing these settings could also lead to the blocking " +
                "of necessary cookies and, therefore, render the site unusable. All instructions relating to the " +
                "management of cookies through the most popular browsers can be found at the following links:",
            "cookie.text.1.7": "The user can manage the tracking preferences of most advertising services through",
            "cookie.popup": "This website uses cookies to ensure you get the best experience. Learn more about"
        }
    },
    it: {
        translation: {
            "title": "Home - Benvenuto a Localadra",
            "menu.home": "Home",
            "menu.dove": "Contatti",
            "menu.who": "Ronco Ceresa",
            "menu.hospitality": "Ospitalità",
            "menu.contacts": "Servizi",
            "who.names": "Localadra Bed and Breakfast",
            "who.role": "a Ronco Ceresa",
            "hospitality.title1": "Camera Levante",
            "hospitality.title2": "Camera Ponente",
            "hospitality.title3": "Appartamento Ex-stalla",
            "hospitality.price1": "da 80 € al giorno",
            "hospitality.price2": "da 80 € al giorno",
            "hospitality.price3": "N.A.",
            "hospitality.desc1": "Camera con bagno privato di 9 mq, doccia spaziosa e lavandino in cemento. E' " +
                "presente Wi-Fi gratuita e Smart TV. La camera, con letto king size, dispone di piccola scrivania, " +
                "lenzuola in lino e doppi cuscini, zanzariere e scuri interni. Dalla camera potrete ammirare l'alba.",
            "hospitality.desc2": "Camera con bagno privato, doccia spaziosa e pavimento in cementine. E' " +
                "presente Wi-Fi gratuita e TV. La camera dispone di lenzuola in lino e " +
                "doppi cuscini, zanzariere e scuri interni ed esterni. Dalla camera potrete ammirare il tramonto.",
            "hospitality.desc3": "L’appartamento, con bagno privato, è ricavato in una stalla ristrutturata. La " +
                "camera da letto è provvista di un letto matrimoniale e di un lettino per bambini fino a 4 anni." +
                " Cucina e sala sono in un unico ambiente e la sala dispone di un divano letto a due posti. E' presente " +
                "la tv, il Wi-Fi e una stufa a legna.",
            "hospitality.quote1": '" A tutti è dovuto il mattino, ad alcuni la notte. A solo pochi eletti la luce ' +
                'dell’aurora "',
            "hospitality.quote2": "\" Tutto è imperfetto, non c’è tramonto così bello da non poterlo essere di più \"",
            "hospitality.quote3": "\" Non esiste certezza che Dio abbia affidato all’uomo il dominio sulle altre " +
                "creature. È invece probabile che l’uomo si sia inventato Dio per santificare il dominio che egli " +
                "ha usurpato sulla mucca e sul cavallo. \"",
            "hospitality.quote.author1": "Emily Dickinson",
            "hospitality.quote.author2": "Fernando Pessoa",
            "hospitality.quote.author3": "Milan Kundera",
            "hospitality.button1": "Richiedi Informazioni",
            "hospitality.button2": "Richiedi Informazioni",
            "hospitality.button3": "Richiedi Informazioni",
            "hospitality.link1": "mailto:info@localadra.it?subject=Richiesta informazioni camera Levante",
            "hospitality.link2": "mailto:info@localadra.it?subject=Richiesta informazioni camera Ponente",
            "hospitality.link3": "mailto:info@localadra.it?subject=Richiesta informazioni camera Ex stalla",
            "about.us": "Il bed and breakfast Localadra si trova all’interno di un antico casolare in sasso chiamato" +
                " \"Ronco Ceresa\", dall’anima grezza e dura tipica delle case rurali Appenniniche. L’esistenza " +
                "del casolare è testimoniata già circa 300 anni fa, ma nel corso degli anni è stato sicuramente " +
                "modificato, lavorato, distrutto e ricostruito. Data la sua posizione strategica è stato base degli" +
                " alleati durante la seconda guerra mondiale. Adagiato su una piccola collina, da qui si spazia a " +
                "360°: il Santuario del Monte delle Formiche lo guarda dal lato sud mentre a ovest lo sguardo arriva" +
                " fino al Monte Cimone. Qui abbiamo dapprima costruito la nostra casa e successivamente, un po’ alla" +
                " volta, abbiamo deciso di aprirla agli altri. Il nostro lavoro è stato quello di recuperare il più" +
                " possibile l’atmosfera della casa di campagna, aggiungendo allo stesso tempo tutti i comfort " +
                "possibili senza snaturarne l’idea. Bologna è la città più vicina e dista solo 30 chilometri ma" +
                " in circa un’ora di auto si arriva a Firenze. I dintorni invece sono tutti da scoprire: ricchi" +
                " di tradizioni e luoghi da ammirare, non mancano di storia e di sentieri da percorrere" +
                " a piedi, in bicicletta o a cavallo. ",
            "services.1": "prima colazione (solo per le camere)",
            "services.2": "wi-fi",
            "services.3": "tv",
            "services.4": "ventilatore portatile (solo per le camere)",
            "services.5": "frigorifero condiviso (solo per le camere)",
            "services.6": "asciugamani",
            "services.7": "lenzuola di lino",
            "services.8": "area parcheggio",
            "privacy.title": "INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI",
            "privacy.text1": "La presente Informativa viene resa per informarVi che i dati conferiti alla nostra " +
                "Società formeranno oggetto di trattamento ai sensi del Regolamento UE n. 2016/679 (in seguito, anche" +
                " “GDPR”) e del D.lgs 101/2018.",
            "privacy.title.h11": "Vi invitiamo pertanto a prendere attenta visione delle informazioni che seguono.",
            "privacy.title.h21": "Titolare del trattamento e della Protezione dei dati.",
            "privacy.text2": "Il Titolare del trattamento dei dati è il B&B Localadra, con sede in Via Ca di Gennaro" +
                ", 3 40065 Pianoro (BO) Italia. Il Titolare del trattamento potrà essere contattato mediante l’invio" +
                " di una lettera Raccomandata A/R all’indirizzo sopra indicato o via e-mail info@localadra.it.",
            "privacy.title.h22": "Finalità del trattamento.",
            "privacy.text3": "I dati personali forniti verranno trattati in relazione alle seguenti finalità " +
                "i.) adempimento di obblighi contrattuali; ii.) adempimento di obblighi di natura contabile e " +
                "fiscale; iii.) gestione dei fornitori (amministrazione dei fornitori, amministrazione dei " +
                "contratti, ordini, consegne e fatture); iv.) gestione della clientela (amministrazione della " +
                "clientela, amministrazione dei contratti, ordini, spedizioni e fatture, controllo dell’" +
                "affidabilità e solvibilità); v.) trattamento giuridico ed economico del personale;  vi.) gestione" +
                " del contenzioso (inadempimenti contrattuali, diffide, transazioni, recupero crediti, arbitrati," +
                " controversie giudiziarie); vii.) adempimento degli obblighi di legge; viii.) interesse legittimo" +
                " del Titolare del trattamento.",
            "privacy.title.h23": "Ambito di comunicazione e diffusione.",
            "privacy.text4": "Vi informiamo che, per il raggiungimento delle finalità sopra indicate, i dati " +
                "raccolti potranno essere comunicati a Terzi – opportunamente designati Responsabili del trattamento" +
                " e i cui riferimenti Vi verranno resi noti a fronte di semplice richiesta, quali ad esempio – " +
                "istituti finanziari, previdenziali e assistenziali, società assicurative, società di servizi " +
                "informatici e telematici, società di archiviazione e conservazione, società di stampa e spedizione," +
                " società di gestione della posta elettronica, autorità giudiziarie o amministrative, commercialisti," +
                " legali, consulenti, medici, società controllate o collegate, centrale dei rischi, società di " +
                "revisione, fondazioni e istituti universitari e non, associazioni di categoria.\n" +
                "\n" +
                "Al di fuori delle predette ipotesi, i Vostri dati personali non saranno comunicati né diffusi in " +
                "assenza di Suo esplicito consenso.",
            "privacy.title.h24": "Modalità del trattamento.",
            "privacy.text5": "Il trattamento sarà realizzato sia in forma cartacea che automatizzata, in conformità " +
                "ai principi di liceità, correttezza, non eccedenza e pertinenza previsti dalla vigente normativa." +
                " Il Titolare del trattamento adotta opportune misure di sicurezza, volte a impedire l’accesso, " +
                "la divulgazione, la modifica o la distruzione non autorizzata dei dati trattati",
            "privacy.title.h25": "Conservazione dei dati.",
            "privacy.text6": "Il Titolare tratterà i dati personali per il tempo strettamente necessario per " +
                "adempiere alle finalità di cui sopra o, comunque, per il periodo previsto per l’adempimento di " +
                "obblighi di legge, sempre che non risulti necessario conservarli ulteriormente per adempiere ad " +
                "ordini delle Autorità.",
            "privacy.title.h26": "Luogo del trattamento e ambito di circolazione dei dati.",
            "privacy.text7": "La gestione e la conservazione dei dati personali avverrà su server del Titolare e/o" +
                " di società terze incaricate e debitamente nominate quali Responsabili del Trattamento, ubicati" +
                " sia nel territorio dell’Unione Europea sia in paesi non appartenenti UE. I dati, quindi, potranno" +
                " essere oggetto di trasferimento sia all’interno che al di fuori dell’Unione Europea. In relazione" +
                " al possibile trasferimento dei dati extra-UE il Titolare assicura sin d’ora che lo stesso avviene" +
                " in conformità alle disposizioni di legge tramite la stipula di accordi che garantiscono un " +
                "livello di protezione adeguato e attraverso l’adozione delle clausole contrattuali standard previste" +
                " dalla Commissione Europea.",
            "privacy.title.h27": "Rifiuto al  conferimento dei dati.",
            "privacy.text8": "Riteniamo inoltre doveroso informarVi che l’eventuale rifiuto ad acconsentire ai" +
                " trattamenti indicati potrebbe impedire il perfezionamento del rapporto contrattuale.",
            "privacy.title.h28": "Diritti degli interessati.",
            "privacy.text9": "In relazione al trattamento dei dati personali eventualmente forniti effettuati dalla" +
                " nostra Società, ricordiamo che, nella vostra qualità di interessati, avete diritto di:" +
                "– ottenere la conferma dell’esistenza o meno di dati personali che Vi riguardano, anche se non" +
                " ancora registrati, e la loro comunicazione in forma intelligibile;" +
                "– ottenere l’indicazione: a) dell’origine dei dati personali; b) delle finalità e modalità del" +
                " trattamento; c) della logica applicata in caso di trattamento effettuato con l’ausilio di " +
                "strumenti elettronici; d) degli estremi identificativi del titolare, dei responsabili e del " +
                "rappresentante designato ai sensi dell’art. 3, comma 1, GDPR e D.lgs 101/2018; e) dei soggetti " +
                "o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono " +
                "venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di " +
                "responsabili o incaricati;" +
                "– ottenere: a) l’aggiornamento, la rettificazione ovvero, quando avete interesse, l’integrazione " +
                "dei dati; b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati " +
                "in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione " +
                "agli scopi per i quali i dati sono stati raccolti o successivamente trattati; c) l’attestazione " +
                "che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto " +
                "riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato " +
                "il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente" +
                " sproporzionato rispetto al diritto tutelato;" +
                "– opporvi, in tutto o in parte: a) per motivi legittimi al trattamento dei dati personali che Vi " +
                "riguardano, ancorché pertinenti allo scopo della raccolta; b) al trattamento di dati personali che " +
                "Vi riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento " +
                "di ricerche di mercato o di comunicazione commerciale, mediante l’uso di sistemi automatizzati di " +
                "chiamata senza l’intervento di un operatore mediante e-mail e/o mediante modalità di marketing " +
                "tradizionali mediante telefono e/o posta cartacea. Si fa presente che il diritto di opposizione " +
                "dell’interessato, esposto al precedente punto b), per finalità di marketing diretto mediante " +
                "modalità automatizzate si estende a quelle tradizionali e che comunque resta salva la possibilità " +
                "per l’interessato di esercitare il diritto di opposizione anche solo in parte. Pertanto, " +
                "l’interessato può decidere di ricevere solo comunicazioni mediante modalità tradizionali ovvero " +
                "solo comunicazioni automatizzate oppure nessuna delle due tipologie di comunicazione;" +
                "– ove applicabili, avete altresì i diritti di cui agli artt. 16-21 GDPR (Diritto di rettifica, " +
                "diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati " +
                "contrattuali e grezzi di navigazione, diritto di opposizione), nonché il diritto di reclamo al " +
                "Garante per la Protezione dei Dati Personali.",
            "privacy.title.h29": "Modifiche all’informativa.",
            "privacy.text10": "L’eventuale entrata in vigore di nuove normative di settore, come anche il " +
                "costante esame ed aggiornamento dei servizi a Voi riservati. potrebbe comportare la necessità " +
                "di modificare le modalità e i termini descritti nella presente Informativa.  È quindi possibile" +
                " che questo documento subisca modifiche nel tempo. Siete pertanto invitati a consultare " +
                "periodicamente la pagina dedicata sul sito aziendale www.localadra.it/privacy " +
                "poiché in essa verranno pubblicate le eventuali modifiche alla presente Informativa.",
            "cookie.title": "Cookie Policy",
            "cookie.h1.1": "TIPOLOGIE DI DATI TRATTATI E FINALITÀ DEL TRATTAMENTO",
            "cookie.text.1": "Nel corso della navigazione su questo sito potrebbero essere trattati alcuni dati" +
                " personali degli utenti, che si possono schematizzare come segue.",
            "cookie.h2.1": "a) Dati forniti volontariamente dall’utente",
            "cookie.text.1.1": "I dati facoltativamente e liberamente forniti dall’utente mediante l’invio di " +
                "messaggi di posta elettronica agli indirizzi email indicati sul sito saranno acquisiti e " +
                "memorizzati dal Titolare del trattamento, in forma elettronica, per finalità di gestione della" +
                " corrispondenza, di fornitura del servizio richiesto dall’utente e per verificare le modalità" +
                " di utilizzo del sito e la qualità dei servizi offerti. In particolare, oltre al nome e " +
                "all’indirizzo di posta elettronica dell’utente, necessario per rispondere alle relative " +
                "richieste, potranno essere acquisiti gli altri dati personali cui la comunicazione elettronica" +
                " faccia eventualmente riferimento o che siano comunque contenuti al suo interno.",
            "cookie.h2.2": "b) Dati di navigazione",
            "cookie.text.1.2": "Potrebbero inoltre essere acquisiti alcuni dati relativi alla navigazione sul" +
                " sito da parte dell’utente. Si tratta, a titolo esemplificativo, di dati demografici, dati " +
                "geografici e di altri dati più specificamente relativi al comportamento dell’utente sul sito." +
                " Tali dati sono raccolti esclusivamente al fine di ottenere informazioni statistiche anonime" +
                " circa l’utilizzo del sito. Tali informazioni, tuttavia, se elaborate e associate con dati" +
                " detenuti da soggetti terzi (e pertanto non in possesso del Titolare del trattamento), " +
                "potrebbero permettere di risalire all’identità dell’utente. I dati di navigazione, in ogni" +
                " caso, non vengono utilizzati dal Titolare del trattamento per compiere attività di " +
                "profilazione diretta dell’utente.",
            "cookie.h2.3": "c) Cookie",
            "cookie.text.1.3": "I cookie sono piccoli file di testo che i siti visitati dall’utente inviano e " +
                "registrano sul suo computer o dispositivo mobile, per essere poi ritrasmessi agli stessi siti " +
                "alla visita successiva. Grazie ai cookie un sito può ricordare le azioni e le preferenze " +
                "dell’utente, in modo che egli non debba eseguirle nuovamente ad ogni accesso al sito o quando" +
                " navighi da una pagina all’altra di esso. I cookie vengono solitamente utilizzati per eseguire" +
                " autenticazioni automatiche, monitoraggio di sessioni e memorizzazione di informazioni " +
                "specifiche riguardanti gli utenti che accedono al server; possono anche contenere codici " +
                "identificativi unici che consentono al gestore del sito di tenere traccia della navigazione " +
                "dell’utente e di riconoscerne il dispositivo. L’utente può disabilitare o accettare i cookie" +
                " configurando adeguatamente il proprio browser. In alcuni casi, tuttavia, l’utilizzo di cookie" +
                " è indispensabile per il funzionamento del sito e, pertanto, alcune operazioni potrebbero non" +
                " essere possibili in caso di loro disattivazione.",
            "cookie.h2.4": "TIPOLOGIE DI COOKIE",
            "cookie.text.1.4": "Esistono diverse tipologie di cookie, i quali, in base alle loro peculiarità e" +
                " funzioni, vengono classificati in: – cookie di sessione (che vengono memorizzati " +
                "temporaneamente sul dispositivo dell’utente, consentendo ai siti web di collegare le azioni " +
                "dallo stesso compiute durante la navigazione, e che si cancellano automaticamente al termine " +
                "della sessione del browser) o cookie permanenti (che, invece, rimangono attivi sul dispositivo" +
                " dell’utente sino alla loro naturale scadenza o alla loro cancellazione manuale e permettono " +
                "di memorizzare stabilmente le preferenze dell’utente rispetto ad un determinato sito web); – " +
                "cookie tecnici (che sono utilizzati al solo fine di effettuare la trasmissione di una " +
                "comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria " +
                "per erogare un servizio esplicitamente richiesto dall’utente) o cookie di profilazione (i quali " +
                "sono invece destinati a creare profili relativi all’utente, al fine di inviargli messaggi " +
                "pubblicitari in linea con le preferenze manifestate dallo stesso nell’ambito della navigazione);" +
                " – cookie di prime parti (impostati e gestiti dal titolare del sito visitato dall’utente) o " +
                "cookie di terze parti (che sono impostati e gestiti da un soggetto terzo rispetto al sito che" +
                " l’utente sta visitando).",
            "cookie.h2.5": "TIPOLOGIE DI COOKIE UTILIZZATE DA QUESTO SITO",
            "cookie.text.1.5": "Nel pieno rispetto dei diritti dell’utente ed al fine di consentire una navigazione" +
                " corretta e consapevole, si precisa che questo sito utilizza le seguenti tipologie di cookie. a)" +
                " Cookie di prima parte: I cookie di prima parte utilizzati sul sito sono hanno natura esclusivamente" +
                " tecnica e si possono distinguere in cookie di navigazione, che permettono di migliorare " +
                "l’esperienza di navigazione dell’utente, e cookie di funzionalità, necessari per erogare il servizio" +
                " o migliorarlo. b) Cookie di terze parti: Questo sito utilizza i cosiddetti “cookie analitici”, " +
                "realizzati e messi a disposizione da Google Analytics e finalizzati a consentire mere analisi " +
                "statistiche di accesso al sito e a monitorarne il corretto funzionamento. Per informazioni " +
                "dettagliate in merito a questa tipologia di cookie, si invita l’utente a consultare la policy " +
                "di Google, raggiungibile al link https://policies.google.com/privacy. Oltre ai cookie analitici" +
                " questo sito utilizza anche dei cosiddetti “widget”. Il sito utilizza, in particolare, i widget di" +
                " Google Maps, i quali, se utilizzati dall’utente, invieranno per conto della piattaforma scelta uno" +
                " o più cookie. Il gestore di questo sito non ha né può avere alcun controllo sul contenuto e sul " +
                "metodo di trattamento dei dati operato attraverso questi widget e pertanto si invita l’utente a " +
                "farne un uso consapevole, previa consultazione della policy di Google " +
                "(https://policies.google.com/privacy). Non viene invece fatto utilizzo, su questo sito, di alcun" +
                " cookie di profilazione.",
            "cookie.h2.6": "GESTIONE DEI COOKIE MEDIANTE CONFIGURAZIONE DEL BROWSER",
            "cookie.text.1.6": "L’utente potrà gestire le preferenze relative ai cookie attraverso le impostazioni " +
                "del proprio browser, tenendo tuttavia presente che la modifica di tali impostazioni potrebbe " +
                "comportare anche il blocco di cookie necessari e, quindi, rendere inutilizzabile il sito." +
                "Tutte le istruzioni relative alla gestione dei cookie tramite i browser più diffusi possono essere " +
                "reperite ai link che seguono:",
            "cookie.text.1.7": "L’utente può gestire le preferenze di tracciamento della maggior parte dei servizi " +
                "pubblicitari attraverso",
            "cookie.popup": "Questo sito web usa i cookie per assicurarti una migliore esperienza. Per maggiori dettagli: "

            }
        },
    fallbackLng: "en",
};
i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
